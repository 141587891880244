.exp-table {
}

.exp-table-default {
  background-color: #f7fafc;
}
.exp-table-row:hover .exp-table-default {
  background-color: #dfe8f5;
  cursor: pointer;
}
.exp-table-default-detail {
  background-color: #e8f0fc;
  cursor: pointer;
}

.exp-table-b {
  background-color: #d4d3ff;
}
.exp-table-row:hover .exp-table-b {
  background-color: #bebbff;
  cursor: pointer;
}
.exp-table-b-detail {
  background-color: #bebbff;
  cursor: pointer;
}

.exp-table-o {
  background-color: #d7f5e5;
}
.exp-table-row:hover .exp-table-o {
  background-color: #bbe8d0;
  cursor: pointer;
}
.exp-table-o-detail {
  background-color: #bbe8d0;
  cursor: pointer;
}

.exp-table-tc {
  background-color: #f9e3ce;
}
.exp-table-row:hover .exp-table-tc {
  background-color: #f9d4b1;
  cursor: pointer;
}
.exp-table-tc-detail {
  background-color: #f9d4b1;
  cursor: pointer;
}
