.loader {
  width: 56px;
  height: 56px;
  border: 8px solid #a5c2f9;
  border-right-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
}
.loader::after {
  content: "";
  width: 8px;
  height: 8px;
  background: #a5c2f9;
  border-radius: 50%;
  position: absolute;
  top: -1px;
  left: 33px;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
