.segment-table {
  display: grid;
  align-items: center;
  border-collapse: collapse;
  min-width: 100%;
  grid-auto-rows: auto;
  /* grid-auto-rows: minmax(min-content, max-content); */
  grid-template-columns: repeat(7, auto);
  /* grid-template-columns:
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr); */
}

.segment-table-head,
.segment-table-body,
.segment-table-head-row,
.segment-table-body-row {
  display: contents;
}

.segment-table-head-row th,
.segment-table-body-row td {
  padding: 10px;
  overflow: auto;
  word-wrap: break-word;
}

.segment-table-head-row th {
  padding: 12px 16px;
  /* position: sticky;
  top: 0; */
  background: #6c7ae0;
  text-align: left;
  font-weight: normal;
  font-size: 1.1rem;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.segment-table-head-cell:last-child {
  border: 0;
}

.segment-table-body-row td {
  padding-top: 6px;
  padding-bottom: 6px;
  color: #333333;
  vertical-align: middle;
  min-height: 100%;
}

.segment-table-body-row:nth-child(even) td {
  background: #f8f9ff;
}
