.default-table {
  border-collapse: collapse;
  width: 100%;
  display: block;
  max-height: 320px;
  overflow-y: scroll;
}

.default-table th,
.default-table td {
  padding: 4px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.default-table th {
  top: 0;
  background: #6262d2;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #f0f0f0;
}

.default-table th:last-child {
  border: 0;
}

.default-table td {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #333333;
}

.default-table tr:nth-child(even) td {
  background: #f8f6ff;
}
